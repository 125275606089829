import React, { useEffect, useState } from 'react'

function Modalpop() {
    const [show, setShow] = useState(false)
    const [cookieShow, setCookieShow] = useState(false)
    const healthCare = () => sessionStorage.getItem('healthCare')
    const cookie = () => sessionStorage.getItem('cookie')

    const modalData = {
        healthCare: {
            heading: "Denna sida är avsedd för hälso- och sjukvårdspersonal.",
            title: "Arbetar du inom hälso- och sjukvården ?",
            buttons: {
                yes: "Ja, det gör jag",
                no: "Nej, det gör jag inte"
            },
            link: "https://www.haleon.com/",
            permission: {
                title: "healthCare",
                value: "yes"
            }
        },
        cookie: {
            heading: `Vi använder nödvändiga cookies för sidans ska funktioner ska fungera som de ska. Vi sparar ingen information som kan spåras till dig som använder websiten. När du klickar på "Tillåt" godkänner du användningen av cookies och lokal lagring. Om du väljer att "Neka" cookies kommer sidan inte fungera som den ska och du skickas till vår startsida.`,
            title: "",
            buttons: {
                yes: "Tillåt",
                no: "Neka"
            },
            link: "https://www.haleon.com/",
            permission: {
                title: "cookie",
                value: "yes"
            }
        }
    }

    const modalCss = {
        display: show || cookieShow ? 'block' : 'none',
        background: 'rgb(54 54 54 / 75%)'
    }
    const modalContainerCss = {
        alignItems: cookieShow ? 'end' : 'center'
    }
    const handlePermissions = (data) => {
        sessionStorage.setItem(data?.title, data?.value)
        if (data.title === "healthCare") {
            setShow(false)
            setCookieShow(true)
        } else {
            setShow(false)
            setCookieShow(false)
        }
    }

    useEffect(() => {
        if (healthCare()) {
            if (cookie()) setCookieShow(false)
            else setCookieShow(true)
        } else setShow(true)
    }, [healthCare, show, cookie, cookieShow])

    const renderModal = (props) => {
        return (
            <div className="modal fade show" tabIndex="-1" role="dialog" aria-hidden={show} style={modalCss}>
                <div className="modal-dialog modal-lg modal-dialog-centered bottom justify-content-center" role="document" style={modalContainerCss}>
                    <div className="modal-content w-auto">
                        <div className="modal-header border-bottom-0" style={{ color: "#003DA5" }}>
                            <h5 className="modal-title" >{props?.heading}</h5>
                        </div>
                        {props?.title === "" ? '' : (
                            <div className="modal-body text-dark">
                                {props?.title}
                            </div>
                        )}
                        <div className="modal-footer justify-content-between border-top-0">
                            <button
                                type="button"
                                className="btn btn-secondary text-white"
                                onClick={() => handlePermissions(props?.permission)}
                                style={{ backgroundColor: '#003da5', borderRadius: '10px' }}
                            >
                                {props?.buttons.yes}
                            </button>
                            <button
                                type="button"
                                className="btn btn-secondary bg-transparent text-dark"
                                onClick={() => window.location.replace('https://www.haleon.com/')}
                                style={{ borderRadius: '10px' }}
                            >
                                {props?.buttons.no}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <>
            {cookieShow ? renderModal(modalData?.cookie) : renderModal(modalData?.healthCare)}
        </>
    )
}

export default Modalpop